/* eslint-disable no-lazy-globals */
window.App = Ember.Namespace.create();
/* eslint-enable no-lazy-globals */

(function () {
	// Global defaults
	moment.locale('en');

	// Polyfills

	if (!Function.prototype.bind) {
		Function.prototype.bind = function (oThis) { // eslint-disable-line no-extend-native
			if (typeof this !== "function") {
				// closest thing possible to the ECMAScript 5 internal IsCallable function
				throw new TypeError("Function.prototype.bind - what is trying to be bound is not callable");
			}

			var aArgs = Array.prototype.slice.call(arguments, 1),
				fToBind = this,
				Fnop = function () { },
				fBound = function () {
					return fToBind.apply((this instanceof Fnop && oThis) ? this : oThis,
						aArgs.concat(Array.prototype.slice.call(arguments)));
				};

			Fnop.prototype = this.prototype;
			fBound.prototype = new Fnop();

			return fBound;
		};
	}

	if (!String.prototype.trim) {
		String.prototype.trim = function () { // eslint-disable-line no-extend-native
			return this.replace(/^\s+|\s+$/g, '');
		};
	}

	if (!Number.isNaN) {
		(function (global) {
			var global_isNaN = global.isNaN;

			Object.defineProperty(Number, 'isNaN', {
				value: function isNaN(value) {
					return typeof value === 'number' && global_isNaN(value);
				},
				configurable: true,
				enumerable: false,
				writable: true
			});
		})(this);
	}

	if (!String.prototype.endsWith) {
		String.prototype.endsWith = function (searchString, position) { // eslint-disable-line no-extend-native
			var subjectString = this.toString();
			if (position === undefined || position > subjectString.length) {
				position = subjectString.length;
			}
			position -= searchString.length;
			var lastIndex = subjectString.indexOf(searchString, position);
			return lastIndex !== -1 && lastIndex === position;
		};
	}

})();


/**
 * Use this like {{pretty-date anyDate}} or {{pretty-date}} (For today)
 * anyDate attribute can hold a String in either "MM/DD/YYYY" format or "YYYY-MM-DD" format
 * anyDate attribute can also hold a Javascript Date or moment object.
 */
window.App.PrettyDateHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var date = params[0];
	var outputFormat = params[1];
	return zen.prettyDate(date, outputFormat);
});

App.AndHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var lhs = params[0], rhs = params[1];
	return lhs && rhs;
});

App.NotHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var value = params[0];
	return !value;
});

App.MaybeHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var value = params[0];
	var noneOutput = params[1];
	if (typeof (value) === "undefined" || value === null) {
		return noneOutput || "N/A";
	}
	return value;
});

App.CapitalizeHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var string = params[0];
	if (string) {
		return Ember.String.capitalize(string);
	}
});
App.OrHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var lhs = params[0], rhs = params[1];
	return lhs || rhs;
});

App.EqualsHelper = Ember.HTMLBars.makeBoundHelper(function (params, hash) {
	var leftSide = params[0],
		rightSide = params[1] || hash.value;
	return leftSide === rightSide;
});

App.MissingHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var object = params[0];
	return typeof object === 'undefined' || object === null;
});

App.ExistsHelper = Ember.HTMLBars.makeBoundHelper(function (params) {
	var object = params[0];
	return typeof object !== 'undefined' && object !== null;
});


(function () {
	var LocalApp = App;
	// Some apps, like implementation re-define App, so here we need to refer to the
	// one from the outer context, so I want to create a new closure.
	Ember.Application.initializer({
		name: "global-helpers",
		initialize: function (registry) {
			function registerHelper(name, helper) {
				// TODO: Remove after Ember 1.13 upgrade
				if (/^1\.11\./.test(Ember.VERSION)) {
					Ember.Handlebars.helpers[name] = helper;
				} else {
					registry.register('helper:' + name, helper);
				}
			}
			registerHelper('maybe', LocalApp.MaybeHelper);
			registerHelper('capitalize', LocalApp.CapitalizeHelper);
			registerHelper('and', LocalApp.AndHelper);
			registerHelper('or', LocalApp.OrHelper);
			registerHelper('equals', LocalApp.EqualsHelper);
			registerHelper('missing', LocalApp.MissingHelper);
			registerHelper('exists', LocalApp.ExistsHelper);
		}
	});
})();

window.thenpath = zen.thenpath = function (promise, path) {
	// we split by . so we do thens all the way down or it won't work
	// e.g. this creates something like:
	//return this.store.find('dashboard', 'me').then(function(model) {
	//	return model.get('company').then(function(company) {
	//		return company.get('onboardingSettings');
	//	});
	//});
	var rec = function (names) {
		var name = names.shift();
		return function (model) {
			var ret = model.get(name);
			if (ret instanceof Ember.ArrayProxy || ret instanceof Ember.RecordArray || Array.isArray(ret) && names.length == 0) {
				return zen.wrapArrayPromise(ret);
			}
			if (names.length == 0) { return ret; }
			return ret && ret.then(rec(names));
		};
	};
	var names = path.split(".");
	return promise.then(rec(names));
};

window.wrapArrayPromise = zen.wrapArrayPromise = function (model) {
	if (Array.isArray(model)) {
		return Ember.RSVP.all(model.map(function (o) {
			return o instanceof Ember.ArrayProxy || Array.isArray(o) ? zen.wrapArrayPromise(o) : o;
		}));
	}
	return model;
};

window.parseZenefitsUrl = zen.parseZenefitsUrl = function (url) {

	var getQueryParams = function (a) {
		if (a == "") return {};
		var b = {};
		for (var i = 0; i < a.length; ++i) {
			var p = a[i].split('=');
			if (p.length != 2) continue;
			b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
		}
		return b;
	};

	var matches = url.match((/^zenefits:\/\/client\/(.*)/));
	if (!matches) {
		return url;
	}

	var queryParams = matches[1].indexOf('?') !== -1 ? getQueryParams(matches[1].split('?')[1].split('&')) : {};

	return {
		route: matches[1].replace(/\//g, '.').split('?')[0],
		params: (Ember.keys(queryParams).length ? { queryParams: queryParams } : null),
	};
};

zen._ApplicationController = Ember.Controller.extend({
	// for chrome when transitioning from a page that's been scrolled down to a new page
	resetScrollTop: function () {
		if (window.document) {
			Ember.$(document).scrollTop(0);
		}
	}.observes('currentPath')
});

window._ApplicationRoute = zen._ApplicationRoute = Ember.Route.extend({
	// N.B. error on other routes should work as well but doesn't seem to on our version of ember
	error: function (reason) {
		if (window.console) { console.error(reason); }
		window.location = '/dashboard';
	},
	init: function () {
		// Not worth the effort to do right
		App.AppRouteInstance = this;
	},
	actions: {
		showModal: function () {
			var name;
			var model;
			var opts;
			var args = Array.prototype.slice.call(arguments);
			var arg;
			var templateName;
			var renderOpts = {
				outlet: 'modal',
				into: 'application'
			};
			var templateName = "modal";

			arg = args.shift();
			// (name, [model])
			if (typeof arg == 'string') {
				name = arg;
				model = args.shift();
				if (model) {
					this.controllerFor(name).set('model', model);
				}
				this.render(name, renderOpts);
				this.set('activeModal', name);
			}
			// (opts)
			else {
				opts = arg;
				renderOpts.controller = opts.controller;

				if (opts && opts.template) {
					templateName = opts.template;
				}

				this.render(templateName, renderOpts);
				this.set('activeModal', 'modal:' + opts.controller);
			}

			setTimeout(function () {
				if (window.document) {
					// hack along with setting position: absolute for snooze-logs dialog in style.css
					$("section.snooze-logs").css("margin-top", $(window).scrollTop() + "px");

					// hack along with setting position: absolute for dialog in style.css
					$(".dialog:visible").css("margin-top", $(window).scrollTop() + "px");
				}
			});
		},
		hideModal: function () {
			this.disconnectOutlet({
				outlet: 'modal',
				parentView: 'application'
			});
			this.set('activeModal', null);
		},
		showPopover: function (name, model) {
			if (model) {
				this.controllerFor(name).set('model', model);
			}
			this.send('hidePopover');
			this.set('activePopover', name);
			Ember.run.next(this, function () {
				this.render(name, {
					outlet: 'popover',
					into: 'application'
				});
			});
		},
		hidePopover: function () {
			this.disconnectOutlet({
				outlet: 'popover',
				parentView: 'application',
			});
			this.set('activePopover', null);
		},
		willTransition: function () {
			if (this.get('activeModal')) {
				this.send('hideModal');
			}
			if (this.get('activePopover')) {
				this.send('hidePopover');
			}
		},
		error: function (e) {
			// Ember.onerror is defined by error-logger initializer only for env==prod
			if (typeof Ember.onerror === 'function') {
				Ember.onerror(e);
			}
			return true;
		},
	},
	beforeModel: function () {
		if (this._super) {
			this._super.apply(this, arguments);
		}
		DS.appStore = this.store;
	},
	setupController: function () {
		this._super.apply(this, arguments);
		DS.appStore = this.store;
		Ember.run.scheduleOnce('afterRender', function () {
			if (document.querySelector('.z-djangoSpinner')) {
				document.querySelector('.z-djangoSpinner').style.display = 'none';
			}
		});
	},
});

if (typeof App !== 'undefined') {
	// This thing is deprecated. Use ths switches ember service instead.
	App.switches = Ember.Object.extend({
		_switches: {},
		_getService: function () {
			var EmberApp = window.ClientApp || window.ConsoleApp;
			if (EmberApp) {
				return EmberApp.__container__.lookup('service:switches:main');
			}
			return null;
		},
		init: function (switchesObj) {
			this.set('_switches', (switchesObj || {}));
			var service = this._getService();
			if (service) {
				service.reset(switchesObj);
			}
		},
		isActive: function (key) {
			Ember.deprecate('The use of App.switches is deprecated, please use services:switches instead, see component-library/app/services/switches.js for more details');
			if (this._getService() && this._getService().get('content')) {
				return this._getService().get('content')[key];
			}
			return this.get('_switches')[key];
		}
	}).create();

	Ember.Handlebars.registerHelper('ifswitch', function (key, options) {
		if (App.switches.isActive(key)) {
			return options.fn(this);
		} else if (options.inverse) {
			return options.inverse(this);
		}
	});

	Ember.Handlebars.registerHelper('ifhr1switch', function (key, options) {
		if (App.switches.isActive('hr1_global') && App.switches.isActive(key)) {
			return options.fn(this);
		} else if (options.inverse) {
			return options.inverse(this);
		}
	});

	Ember.Handlebars.registerHelper('brandName', function () {
		return window.BRAND_NAME;
	});
}

// Programmatically create a bunch of convenience API functions
(function () {
	var apis = [
		'companyEnrollmentApi',
		'companyEnrollmentReviewApi',
		'companyEnrollmentFinishEditApi',
		'companyEnrollmentReviewfinishApi',
		'companyEnrollmentSettingsApi',
		'companyBorEnrollmentCarriersApi',
		'companyEnrollmentBorApi',
		'businessInsuranceEnrollmentBorApi',
		'shortCircuitPlansApi',
		'stateCarrierApi',
		'cplansApi',
		'employeeLifeDisabilityEnrollmentApi',
		'companyLifeDisabilityEnrollmentApi',
		'carrierAndEmployerCredentialsApi',
		'companyCobraEnrollmentApi'
	];
	// e.g., zen.companyEnrollmentApi = function (path) {
	// 			return zen.thenpath(App.CompanyEnrollmentApi.find('me'), path);
	// 		};
	apis.forEach(function (api) {
		var apiFunc = function (path) {
			/* eslint-disable no-lazy-globals */
			return zen.thenpath(App[api.capitalize()].find('me'), path);
			/* eslint-enable no-lazy-globals */
		};
		/* eslint-disable no-lazy-globals */
		zen[api] = apiFunc;
		/* eslint-enable no-lazy-globals */

		// ====================
		// FIXME: This is a hack, creates globals used throughout the app
		// Please remove when possible, once refs are updated to point to
		// zen namespace
		// ====================
		/* eslint-disable no-lazy-globals */
		window[api] = apiFunc;
		/* eslint-enable no-lazy-globals */
	});
})();

window.parseAmericanDate = zen.parseAmericanDate = function (s) {
	var match = /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)[0-9]{2})$/.exec(s);
	if (!match || match.length < 4) { return null; }
	var year = Number(match[3]);
	if (year < 50) {
		year += 2000;
	}
	else if (year < 100) {
		year += 1900;
	}
	return {
		"month": Number(match[1]),
		"day": Number(match[2]),
		"year": year
	};
};

window.americanDateAsDate = zen.americanDateAsDate = function (dateString) {
	var dateObj = zen.parseAmericanDate(dateString);
	if (dateObj) {
		return new Date(dateObj.year, dateObj.month - 1, dateObj.day);
	}
	return null;
};

window.parseISODateTime = zen.parseISODateTime = function (s) {
	if (!s) { return null; }
	var match = /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)(:?\.\d+)*([+-]\d\d:\d\d)*/.exec(s);
	if (!match) { return null; }
	var year = Number(match[1]);
	// java style 0-11 months
	var month = Number(match[2]) - 1;
	var day = Number(match[3]);
	var hour = Number(match[4]);
	var minutes = Number(match[5]);
	var seconds = Number(match[6]);
	return new Date(Date.UTC(year, month, day, hour, minutes, seconds));
};

window.FILEPICKER_KEY = (window.location.host.indexOf('localhost') === 0 ||
	window.location.host.indexOf('spoof.zenefits.com') !== -1) ? 'A7UTkXMg0QHi0WKu8A8NXz' : 'AxECwBio2RwKkJWV7pzRVz';

// Extendion Ember :(

// Always explicitly resolve ajax promises.
(function () {
	function jQueryRequestWrapper(requestType) {
		return function () {
			var _this = this;
			var args = arguments;

			return new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$[requestType].apply(_this, args).done(function (data, textStatus, xhr) {
					resolve(data);
				}).fail(function (xhr, textStatus, errorThrown) {
					reject(xhr);
				});
			});
		};
	}

	Ember.ajaxGet = jQueryRequestWrapper('get');
	Ember.ajaxPost = jQueryRequestWrapper('post');

	// https://api.jquery.com/jquery.ajax/#jQuery-ajax-settings
	Ember.ajaxSettings = jQueryRequestWrapper('ajax');
})();

Ember.getJSON = function () {
	return Ember.RSVP.resolve(Ember.$.getJSON.apply(this, arguments));
};

Ember.ajax = function () {
	return Ember.RSVP.resolve(Ember.$.ajax.apply(this, arguments));
};

// TODO: remove this in favor of just using Date.now it is supported everywhere
// since the beginning of time, see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/now
Ember.now = Date.now || function () { return new Date().getTime(); };

// Ember async wrapper for JQuery/Velocity animations
// Ex. use: Ember.animate($elem.slideUp().slideDown());
// Ex. use: Ember.animate(Ember.$.Velocity.animate(this.$('.js-modal'), { opacity: 0 }, 300));
// Ex. use (deprecated): Ember.animate(function () { return $elem.slideUp().slideDown(); });
Ember.animate = function (param) {
	var jqPromise;
	var anim = Ember.animate;
	var id = anim.id;
	anim.animations[id] = 'animating';
	anim.id++;
	var remove = function () {
		delete anim.animations[id];
	};
	if (typeof param == 'function') {
		Ember.deprecate('Passing a function to Ember.animate is now deprecated and may be removed in a future version. ' +
			'Supported parameters are then-able Promises and jQuery collections.');
		param = param();
	}
	// then-able Promise
	if (param && typeof param.then == 'function') {
		// native ES6 promises don't support .finally()
		// https://esdiscuss.org/topic/why-the-ecmascript-2015-promises-don-t-have-finally-methods
		return param.then(function (result) {
			remove();
			return result;
		}, function (reason) {
			remove();
			throw reason;
		});
	}
	// jQuery collection / promisey API
	else if (param && typeof param.promise == 'function') {
		jqPromise = param.promise();
		return new Ember.RSVP.Promise(function (resolve, reject) {
			jqPromise.done(resolve);
			jqPromise.fail(reject);
			jqPromise.always(remove);
		});
	} else {
		remove();
		var e = new Error(
			'Invalid parameter to Ember.animate(): ' +
			param +
			'. Supported parameters are then-able Promises and jQuery collections.'
		);
		e.param = param;
		if (!!window._phantom) {
			// In Phantom we loose some of the params passed to animate
			// so we don't throw, just log it instead
			console.error(e);
			return Ember.RSVP.resolve();
		} else {
			throw e;
		}
	}
};
Ember.animate.id = 0;
Ember.animate.animations = {};

// Required for models

App.PersonalPronounMap = Ember.Object.create({
	"0": Ember.Object.create({
		"subjective": "e",
		"objective": "em",
		"possessive": "eir",
		"reflexive": "emself"
	}),
	"1": Ember.Object.create({
		"subjective": "ey",
		"objective": "em",
		"possessive": "eir",
		"reflexive": "eirself"
	}),
	"2": Ember.Object.create({
		"subjective": "ey",
		"objective": "em",
		"possessive": "eir",
		"reflexive": "emself"
	}),
	"3": Ember.Object.create({
		"subjective": "fae",
		"objective": "faer",
		"possessive": "faers",
		"reflexive": "faerself"
	}),
	"4": Ember.Object.create({
		"subjective": "he",
		"objective": "him",
		"possessive": "his",
		"reflexive": "himself"
	}),
	"5": Ember.Object.create({
		"subjective": "hu",
		"objective": "hu",
		"possessive": "humes",
		"reflexive": "humself"
	}),
	"6": Ember.Object.create({
		"subjective": "she",
		"objective": "her",
		"possessive": "hers",
		"reflexive": "herself"
	}),
	"7": Ember.Object.create({
		"subjective": "sie",
		"objective": "hir",
		"possessive": "hirs",
		"reflexive": "hirself"
	}),
	"8": Ember.Object.create({
		"subjective": "tey",
		"objective": "ter",
		"possessive": "ters",
		"reflexive": "terself"
	}),
	"9": Ember.Object.create({
		"subjective": "tey",
		"objective": "tem",
		"possessive": "ters",
		"reflexive": "terself"
	}),
	"10": Ember.Object.create({
		"subjective": "they",
		"objective": "them",
		"possessive": "their",
		"reflexive": "themself"
	}),
	"11": Ember.Object.create({
		"subjective": "ve",
		"objective": "ver",
		"possessive": "vers",
		"reflexive": "verself"
	}),
	"12": Ember.Object.create({
		"subjective": "xe",
		"objective": "xem",
		"possessive": "xyrs",
		"reflexive": "xemself"
	}),
	"13": Ember.Object.create({
		"subjective": "ze",
		"objective": "hir",
		"possessive": "hirs",
		"reflexive": "hirself"
	}),
	"14": Ember.Object.create({
		"subjective": "ze",
		"objective": "zir",
		"possessive": "zirs",
		"reflexive": "zirself"
	}),
	"15": Ember.Object.create({
		"subjective": "zie",
		"objective": "zim",
		"possessive": "zis",
		"reflexive": "zieself"
	}),
});

window.BRAND_NAME = "TriNet";
window.STATES_WITH_NO_INCOME_TAX = ['AK', 'FL', 'NV', 'NH', 'SD', 'TX', 'TN', 'WA', 'WY'];
window.STATES_THAT_USE_FEDERAL = ['CO', 'DE', 'ID', 'MT', 'NM', 'ND', 'OK', 'OR', 'SC', 'UT'];
window.STATES_THAT_MATCH_FEDERAL_FILING_STATUS = ['CO', 'DE', 'ID', 'MN', 'NM', 'ND', 'OK', 'OR', 'RI', 'SC', 'UT'];
window.STATES_NOT_MATCHING_FEDERAL_STATUS = ['MT', 'VT'];
window.STATES_THAT_MATCH_FEDERAL_ALLOWANCE = ['CO', 'ND', 'UT'];
window.STATES_NOT_MATCHING_FEDERAL_ALLOWANCE = ['DE', 'ID', 'MN', 'MT', 'NM', 'OK', 'OR', 'RI', 'SC', 'VT'];
window.STATES_WITH_NO_WITHHOLDING_ALLOWANCE = ['PA', 'MO', 'MT', 'KY'];
// These states have more than one withholding allowance field in W4 and we don't show the normal
// stateWithholdingAllowance section but show sections which have custom state withholding allowances
window.STATES_WITH_NO_ADDITIONAL_WITHHOLDING = ['PA'];
window.STATES_WITH_NO_FILING_STATUS = ['AZ', 'CT', 'IL', 'IN', 'KY', 'MI', 'OH', 'PA', 'RI', 'VA', 'WV'];
window.STATES_WITH_LOCAL_TAX = ['NY', 'MI'];
window.STATE_FILING_STATUSES = {
	"AL": [{ "id": "0", "name": "No Personal Exemption" }, { "id": "S", "name": "Single" }, { "id": "MS", "name": "Married Filing Separately" }, { "id": "MJ", "name": "Married Filing Jointly" }, { "id": "HH", "name": "Head of Family" }],
	"AR": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married Filing Jointly" }, { "id": "HH", "name": "Head of household" }],
	"CA": [{ "id": "S", "name": "Single or Married (with two or more incomes)" }, { "id": "MS", "name": "Married (one income)" }, { "id": "HH", "name": "Head of Household" }],
	"DC": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married/domestic partners Filing Jointly" }, { "id": "MS", "name": "Married Filing Separately" }, { "id": "HH", "name": "Head of Household" }, { "id": "MSJ", "name": "Married/domestic partners filing separately on same return" }],
	"GA": [{ "id": "S", "name": "Single" }, { "id": "MS", "name": "Married Filing Separate or Married Filing Joint both spouses working" }, { "id": "JN", "name": "Married Filing Joint, one spouse working" }, { "id": "HH", "name": "Head of household" }],
	"HI": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but withhold at higher Single rate" }],
	"IA": [{ "id": "S", "name": "Single; Married, but legally separated" }, { "id": "MJ", "name": "Married" }],
	"KS": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Joint" }],
	"LA": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "E", "name": "No exemptions or dependents claimed" }],
	"MA": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married Filing Jointly" }, { "id": "HH", "name": "Head of household" }],
	"MD": [{ "id": "S", "name": "Single" }, { "id": "MS", "name": "Married, but withhold at Single rate" }, { "id": "HH", "name": "Married (surviving spouse or unmarried Head of Household) Rate" }],
	"ME": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but witholding at higher single rate" }],
	"MN": [{ "id": "S", "name": "Single; Married, but legally separated; or Spouse is a nonresident alien" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but withhold at higher Single rate" }],
	"MO": [{ "id": "S", "name": "Single or Married Spouse Works or Married Filing Separate" }, { "id": "MJ", "name": "Married (Spouse does not work)" }, { "id": "HH", "name": "Head of household" }],
	"MS": [{ "id": "S", "name": "Single" }, { "id": "MSE", "name": "Married, Spouse Employed" }, { "id": "MSN", "name": "Married, Spouse Not Employed" }, { "id": "HH", "name": "Head of Family" }],
	"MT": [{ "id": "S", "name": "Single or Married filing separately" }, { "id": "MJ", "name": "Married filing jointly (or Qualifying widow(er))" }, { "id": "HH", "name": "Head of household" }],
	"NE": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }],
	"NC": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married or Qualifying Widow(er)" }, { "id": "HH", "name": "Head of household" }],
	"NJ": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married/Civil Union Couple Joint" }, { "id": "MS", "name": "Married/Civil Union Partner Separate" }, { "id": "HH", "name": "Head of Household" }, { "id": "WD", "name": "Qualifying Widow(er)/Surviving Civil Union Partner" }],
	"NY": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but witholding at higher single rate" }, { "id": "HH", "name": "Head of household" }],
	"UT": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but witholding at higher single rate" }, { "id": "HH", "name": "Head of household" }],
	"VT": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but withhold at the higher Single Rate" }, { "id": "CU", "name": "Civil Union" }, { "id": "CUS", "name": "Civil Union, but withhold at the higher Single Rate" }],
	"WI": [{ "id": "S", "name": "Single" }, { "id": "MJ", "name": "Married" }, { "id": "MS", "name": "Married, but withhold at higher Single rate" }],
};
window.FEDERAL_FILING_STATUS_OPTIONS = zen.FEDERAL_FILING_STATUS_OPTIONS = {
	'v1': [
		{ "name": "Single", "id": "S" },
		{ "name": "Married", "id": "MJ" },
		{ "name": "Married, but withhold at higher Single rate", "id": "MS" },
	],
	'v2': [
		{ "name": "Single or Married filing separately", "id": "S" },
		{ "name": "Married filing jointly (or Qualifying widow(er))", "id": "MJ" },
		{ "name": "Head of household", "id": "HH" },
	]
};

//Duplicated in the python IF YOU CHANGE THIS CHANGE THAT!
//  register_company/models.py
//    	grep for pangolin
//  TODO: should be served up...
window.STATE_WITHHOLDING_OPTIONS = {
	"AZ": [{ "id": "0.5", "name": "0.5%" }, { "id": "1.0", "name": "1.0%" }, { "id": "1.5", "name": "1.5%" }, { "id": "2.0", "name": "2.0%" }, { "id": "2.5", "name": "2.5%" }, { "id": "3.0", "name": "3.0%" }, { "id": "3.5", "name": "3.5%" }],
	"CT": [{ "id": "A", "name": "A" }, { "id": "B", "name": "B" }, { "id": "C", "name": "C" }, { "id": "D", "name": "D" }, { "id": "E", "name": "E" }, { "id": "F", "name": "F" }],
	"LA": [{ "id": "0", "name": "0" }, { "id": "1", "name": "1" }, { "id": "2", "name": "2" }]
};

window.RECIPROCAL_STATES = {
	'DC': ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'],
	'IA': ['IL'],
	'IL': ['IA', 'KY', 'MI', 'WI'],
	'IN': ['KY', 'MI', 'OH', 'PA', 'WI'],
	'KY': ['IL', 'IN', 'MI', 'OH', 'VA', 'WI', 'WV'],
	'MD': ['DC', 'PA', 'VA', 'WV'],
	'MI': ['IL', 'IN', 'KY', 'MN', 'OH', 'WI'],
	'MN': ['MI', 'ND'],
	'MT': ['ND'],
	'ND': ['MN', 'MT'],
	'NJ': ['PA'],
	'OH': ['IN', 'KY', 'MI', 'PA', 'WV'],
	'PA': ['IN', 'MD', 'NJ', 'OH', 'VA', 'WV'],
	'VA': ['DC', 'KY', 'MD', 'PA', 'WV'],
	'WI': ['IL', 'IN', 'KY', 'MI'],
	'WV': ['KY', 'MD', 'OH', 'PA', 'VA'],
};

App.PtoWarningMessages = {
	invalid_start_date: 'Please select a valid start date',
	invalid_end_date: 'Please select a valid end date',
	vacation_overlap_error: 'The vacation overlaps with an existing vacation from %@ to %@',
	partial_day_off_hours_exceeded: 'The vacation exceeds the number of hours per day by %@ hours because it is on the same day as an existing vacation',
	vacation_type_not_selected: 'Please select a type',
	date_order_invalid: 'End date cannot be before start date, please adjust dates.',
	special_characters_not_allowed: 'Please don\'t use special characters!',
	negative_balance_exceeded: 'You have negative balance that exceeds the company cap of %@ hours'
};

window.payToSigFigs = zen.payToSigFigs = function (d) {
	return Number(d).toFixed(2);
};

window.getNextDateFromString = zen.getNextDateFromString = function (strDate) {
	var date = new Date(strDate);
	date.setDate(date.getDate() + 1);

	return date;
};

zen._isStateMixin = Ember.Mixin.create((function () {
	var ret = {};
	Object.keys(zen.constants.STATE_ABBREV_TO_NAME).forEach(function (stateCode) {
		ret['is' + stateCode] = Ember.computed('state', function () {
			return this.get('state') == stateCode;
		});
	});
	return ret;
})());

window.longDate = zen.longDate = function (s) {
	var date = zen.parseAmericanDate(s);
	if (!date) { return ""; }
	var shortMonthName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][date.month - 1];
	return shortMonthName + " " + date.day + ", " + date.year;
};

//transforms a number to a string with a fixed max number of digits, e.g.:
//(3.125, 2) => 3.12
//(3.000, 2) => 3
window.toAtMostNDigits = zen.toAtMostNDigits = function (number, n) {
	return Number(parseFloat(number).toFixed(n)).toString();
};

window.dateAsString = zen.dateAsString = function (date) {
	var date_string = (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + "/";
	date_string += (date.getDate() < 10 ? "0" : '') + date.getDate() + "/";
	date_string += date.getFullYear();

	return date_string;
};

Ember.computed.difference = function (aKey, bKey) {
	return Ember.computed(function () {
		return this.get(aKey) - this.get(bKey);
	}).property(aKey, bKey);
};

Ember.computed.prefix = function (prefix, key) {
	return Ember.computed(function () {
		return prefix + this.get(key);
	}).property(key);
};

Ember.computed.payToSigFigs = function (key) {
	return Ember.computed(function () {
		return zen.payToSigFigs(this.get(key));
	}).property(key);
};

//Makes an attribute holding a date into a pretty version of a date, e.g. Aug. 5, 2014 -- setter not implemented
Ember.computed.prettyDate = function (attribute, outputFormat, defaultValue) {
	outputFormat = outputFormat || "MMM D, YYYY";
	defaultValue = arguments.length > 2 ? defaultValue : "N/A";
	return function () {
		var dateString = this.get(attribute);
		if (!dateString) {
			return defaultValue;
		}

		if (dateString instanceof Date) {
			return moment(dateString).format(outputFormat);
		}

		var momentDate = moment(dateString, "M/D/YYYY", true);
		if (momentDate.isValid()) {
			return momentDate.format(outputFormat);
		}

		momentDate = moment(dateString, "M/D/YY", true);
		if (momentDate.isValid()) {
			return momentDate.format(outputFormat);
		}

		return moment(dateString, "YYYY-M-D").format(outputFormat);
	}.property(attribute);
};

Ember.computed.asMoment = function (key, parseFormat) {
	parseFormat = parseFormat || [moment.ISO_8601, 'M/D/YYYY', 'YYYY-M-D'];
	return Ember.computed(function () {
		var val = moment(this.get(key), parseFormat, true);
		if (val.isValid()) {
			return val;
		}
		return null;
	}).property(key);
};

//Makes an editable attribute which shows a truncated number of digits, while maintaining larger percision internally.
Ember.computed.truncated = function (attribute, digits) {
	return function (key, value) {
		//setter
		if (arguments.length > 1) {
			this.set(attribute, value);
			return value;
		}

		//getter
		var oldValue = this.get(attribute);
		if (oldValue) {
			return zen.toAtMostNDigits(oldValue, digits);
		} else {
			return oldValue;
		}
	}.property(attribute);
};

//Makes an attribute that inserts commas (or local appropriate seperators) into another number attribute for $ amounts.
Ember.computed.commas = function (attribute) {
	return function (key, value) {
		if (value) {
			this.set(attribute, value);
		}
		var attributeValue = this.get(attribute);
		if (attributeValue == null || attributeValue == "") {
			return "";
		}
		if (zen.toAtMostNDigits(attributeValue, 2) == zen.toAtMostNDigits(attributeValue, 0)) {
			return Number(parseFloat(String(attributeValue).replace(/,/g, '')).toFixed(0)).toLocaleString();
		} else {
			return Number(parseFloat(String(attributeValue).replace(/,/g, '')).toFixed(2)).toLocaleString();
		}
	}.property(attribute);
};

Ember.computed.flowSectionIsComplete = function (flowProp, sectionName) {
	return Ember.computed(function () {
		var sections = this.get(flowProp + '.sections');
		if (!sections) {
			return false;
		}
		return !!sections.find(function (section) {
			return section.get('name') === sectionName && section.get('isComplete') && section.get('isReady') && section.get('isEntered');
		});
	}).property(flowProp + '.sections.@each.name', flowProp + '.sections.@each.isComplete', flowProp + '.sections.@each.isReady', flowProp + '.sections.@each.isEntered');
};

Ember.computed.flowSectionIsPresent = function (flowProp, sectionName) {
	return Ember.computed(function () {
		var sections = this.get(flowProp + '.sections');
		if (!sections) {
			return false;
		}
		return !!sections.find(function (section) {
			return section.get('name') === sectionName;
		});
	}).property(flowProp + '.sections.@each.name');
};

Ember.computed.filterByProperty = function (arrKey, propKey, value) {
	var args = arguments;
	return Ember.computed(function () {
		if (!this.get(arrKey)) {
			return this.get(arrKey);
		}
		if (args.length === 2) {
			return this.get(arrKey).filterProperty(propKey);
		} else {
			return this.get(arrKey).filterProperty(propKey, value);
		}
	}).property(arrKey + '.@each.' + propKey, arrKey + '.@each', arrKey);
};

Ember.computed.mapByProperty = function (arrkey, propKey) {
	return Ember.computed(function () {
		if (!this.get(arrkey)) {
			return this.get(arrkey);
		}
		var a = Ember.A();
		//mapProperty will return a null object as well, so forEach
		this.get(arrkey).forEach(function (arrObj) {
			if (arrObj.get(propKey)) {
				a.pushObject(arrObj.get(propKey));
			}
		});
		return a;
	}).property(arrkey + '.@each.' + propKey);
};

Ember.computed.arePropertiesEqual = function (propKey1, propKey2) {
	return Ember.computed(function () {
		return this.get(propKey1) == this.get(propKey2);
	}).property(propKey1, propKey2);
};

Ember.computed.arePropertiesUnEqual = function (propKey1, propKey2) {
	return Ember.computed(function () {
		return this.get(propKey1) != this.get(propKey2);
	}).property(propKey1, propKey2);
};

Ember.computed.getFirstProperty = function (arrkey, propKey) {
	return Ember.computed(function () {
		if (!this.get(arrkey)) {
			return this.get(arrkey);
		}
		var result = null;
		this.get(arrkey).forEach(function (arrObj) {
			if (arrObj && arrObj.get(propKey)) {
				result = arrObj.get(propKey);
			}
		});
		return result;
	}).property(arrkey + '.@each.' + propKey);
};

Ember.computed.rejectByProperty = function (arrKey, propKey, value) {
	var args = arguments;
	return Ember.computed(function () {
		if (!this.get(arrKey)) {
			return this.get(arrKey);
		}
		if (args.length === 2) {
			return this.get(arrKey).filter(function (obj) {
				return !Ember.get(obj, propKey);
			});
		} else {
			return this.get(arrKey).filter(function (obj) {
				return obj.get(propKey) != value;
			});
		}
	}).property(arrKey + '.@each.' + propKey, arrKey + '.@each', arrKey);
};

Ember.computed.findByProperty = function (arrKey, propKey, value) {
	var args = arguments;
	return Ember.computed(function () {
		if (args.length === 2) {
			return this.get(arrKey).findProperty(propKey);
		} else {
			return this.get(arrKey).findProperty(propKey, value);
		}
	}).property(arrKey + '.@each.' + propKey);
};


Ember.computed.anyProperty = function (arrKey, propKey, value) {
	var args = arguments;
	return Ember.computed(function () {
		var attribute = this.get(arrKey);
		if (!attribute) {
			return false;
		}
		if (args.length === 2) {
			return !!attribute.findProperty(propKey);
		} else {
			return !!attribute.findProperty(propKey, value);
		}
	}).property(arrKey + '.@each.' + propKey);
};

Ember.computed.contains = function (arrKey, value) {
	//this could be called Ember.computed.any consistantly with our nomenclature,
	//however that name is defined differently in the actual Ember computed module
	return Ember.computed(function () {
		return this.get(arrKey).contains(value);
	}).property(arrKey + '.@each');
};

Ember.computed.in = function (arrKey, value) {
	//opposite of contains, check if property is one of multiple choices in an array
	return Ember.computed(function () {
		return value.contains(this.get(arrKey));
	}).property(arrKey);
};

Ember.computed.isFalse = function (key) {
	return Ember.computed(function () {
		return this.get(key) === false;
	}).property(key);
};


Ember.computed.hoursToDays = function (attribute) {
	return function (key, value) {
		//setter
		if (arguments.length > 1) {
			if ($.isNumeric(value)) {
				this.set(attribute, (8.0 * value));
				return value;
			} else {
				this.set(attribute, null);
				return value;
			}
		}

		//getter
		if ($.isNumeric(this.get(attribute))) {
			return zen.toAtMostNDigits(this.get(attribute) / 8.0, 2);
		}
	}.property(attribute);
};

Ember.computed.hoursPerMonthToDaysPerYear = function (attribute, workdayHoursAttribute) {
	return function (key, daysPerYear) {
		//setter
		if (arguments.length > 1) {
			var hoursPerDay = this.get(workdayHoursAttribute);
			if (!$.isNumeric(daysPerYear) || !$.isNumeric(hoursPerDay)) {
				this.set(attribute, null);
				return null;
			}

			var hoursPerMonth = daysPerYear / 12.0 * hoursPerDay;
			this.set(attribute, hoursPerMonth);
			return daysPerYear;
		}

		//getter
		var rate = this.get(attribute);
		var hoursPerDay = this.get(workdayHoursAttribute);
		if ($.isNumeric(this.get(attribute)) || $.isNumeric(hoursPerDay)) {
			return zen.toAtMostNDigits(12.0 * rate / hoursPerDay, 2);
		}
	}.property(attribute, workdayHoursAttribute);
};

Ember.computed.boolToYesNo = function (attribute) {
	return function (key, value) {
		//setter
		if (arguments.length > 1) {
			this.set(attribute, value === 'yes');
		}

		return this.get(attribute) ? 'yes' : 'no';
	}.property(attribute);
};


Ember.computed.nullBoolToYesNo = function (attribute) {
	return function (key, value) {
		//setter
		if (arguments.length > 1) {
			if (value === 'yes') {
				this.set(attribute, true);
			} else if (value === 'no') {
				this.set(attribute, false);
			} else {
				this.set(attribute, null);
			}
		}
		if (this.get(attribute) === null) {
			return null;
		} else {
			return this.get(attribute) ? 'yes' : 'no';
		}
	}.property(attribute);
};

Ember.computed.number = function (path) {
	return Ember.computed(function () {
		return Number(this.get(path));
	}).property(path);
};

Ember.computed.allNotEmpty = function () {
	var keys = Array.prototype.slice.call(arguments, 0);
	var computed = Ember.computed(function () {
		var context = this;
		var result = true;
		keys.forEach(function (key) {
			result = result && !Ember.isEmpty(context.get(key));
		});
		return result;
	});
	computed.property.apply(computed, keys);
	return computed;
};

(function () {
	//sets the index bit in integer to 1 or 0, depending on bool
	var setbit = function (integer, index, bool) {
		var hasBitAtIndex = !!((1 << index) & integer);
		var needsToSwap = hasBitAtIndex ^ bool;
		return needsToSwap ? integer ^ (1 << index) : integer;
	};
	//Takes a field containing an integer and makes a property that reflects the value of the bit at index
	Ember.computed.bitwiseBooleanAtIndex = function (attribute, index) {
		return function (key, value) {
			if (arguments.length > 1) {
				var currentValue = this.get(attribute);
				this.set(attribute, setbit(currentValue, index, value));
			}
			return !!(this.get(attribute) & (1 << index));
		}.property(attribute);
	};
})();

Ember.computed.unequal = function (key, value) {
	return Ember.computed(function () {
		return this.get(key) != value;
	}).property(key);
};

Ember.computed.count = function (arrayKey, propertyKey, value) {
	return Ember.computed(function () {
		var array = this.get(arrayKey) || [];
		var args = arguments;
		if (args.length === 2) {
			return array.reduce(function (count, obj) {
				return count + obj.get(propertyKey) ? 1 : 0;
			}, 0);
		} else {
			return array.reduce(function (count, obj) {
				return count + obj.get(propertyKey) == value ? 1 : 0;
			}, 0);
		}
	}).property(arrayKey + '.@each.' + propertyKey);
};

Ember.computed.split = function (key, split) {
	return Ember.computed(function () {
		return this.get(key) ? this.get(key).split(split) : Ember.A();
	}).property(key);
};

Ember.computed.localeString = function (key) {
	return Ember.computed(function () {
		return this.get(key).toLocaleString();
	}).property(key);
};

Ember.computed.sumByProperty = function (arrKey, propKey) {
	return Ember.computed(function () {
		return this.get(arrKey).filterProperty(propKey).reduce(function (total, entry) {
			return total + (parseFloat(entry.get(propKey)) || 0);
		}, 0);
	}).property(arrKey + ".@each." + propKey);
};

Ember.computed.boolString = function (key) {
	return Ember.computed(function () {
		if (this.get(key) == null || this.get(key) == undefined) {
			return null;
		}
		if (this.get(key)) {
			return 'true';
		}

		return 'false';
	}).property(key);
};

(function () {
	var getMoment = function (timeStr, tzStr) {
		if (typeof moment === "undefined") {
			return null;
		}

		var momTime = moment(timeStr, moment.defaultFormat, true);

		return tzStr ? momTime.tz(tzStr) : momTime;
	};

	Ember.computed.momentTz = function (timeKey, tzKey) {
		return Ember.computed(function () {
			return getMoment(this.get(timeKey), this.get(tzKey));
		}).property(timeKey, tzKey);
	};

	var momentSort = function (sortingKey, descending) {
		return function (a, b) {
			if (typeof moment === "undefined") {
				return false;
			}

			var mta = getMoment(a.get(sortingKey));
			var mtb = getMoment(b.get(sortingKey));

			if (!mta.isValid() || !mtb.isValid()) {
				return false;
			}

			return descending ? mta.isBefore(mtb) : mta.isAfter(mtb);
		};
	};

	Ember.computed.momentSorted = function (key, sortingKey, descending) {
		return Ember.computed(function () {
			return this.get(key).toArray().sort(momentSort(sortingKey, descending));
		}).property(key + '.@each.' + sortingKey);
	};

	Ember.computed.dateSorted = function (key, sortingKey, descending) {
		return Ember.computed(function () {
			return this.get(key).toArray().sort(function (a, b) {
				return descending ? new Date(b.get(sortingKey)) - new Date(a.get(sortingKey)) : new Date(a.get(sortingKey)) - new Date(b.get(sortingKey));
			});
		}).property(key + '.@each.' + sortingKey);
	};

})();

zen._CustomFieldValueSectionGroupsMixin = Ember.Mixin.create({
	getCustomFieldValueSectionGroups: function (customfieldvalue) {
		var sections = this.get(customfieldvalue).mapProperty('customField.customFieldSection').uniq();
		var hasNull = sections.indexOf(null) != -1;
		if (hasNull) {
			sections.splice(sections.indexOf(null), 1);
		}
		sections.sort(function (a, b) {
			return a.get('rank') - b.get('rank');
		});
		if (hasNull) {
			sections.pushObject(null);
		}
		var values = [];
		sections.forEach(function (section) {
			var customFieldValueSectionGroup = Ember.ArrayProxy.createWithMixins(Ember.SortableMixin, {
				sortProperties: ['customField.rank'],
				content: this.get(customfieldvalue).filterProperty('customField.customFieldSection', section).filterProperty('isNotFiltered'),
			});
			customFieldValueSectionGroup.set('hasEmployerDuringHiring', customFieldValueSectionGroup.isAny('customField.employerDuringHiring'));
			customFieldValueSectionGroup.set('hasEmployeeViewField', customFieldValueSectionGroup.isAny('customField.canEmployeeViewField'));
			customFieldValueSectionGroup.set('hasEmployeeDuringOnboarding', customFieldValueSectionGroup.isAny('customField.employeeDuringOnboarding'));
			customFieldValueSectionGroup.set('hasFieldCompleterEmployee', customFieldValueSectionGroup.isAny('customField.fieldCompleterEmployee'));
			values.pushObject(customFieldValueSectionGroup);
		}.bind(this));
		return values;
	}
});

Ember.computed.notNone = function (key) {
	return Ember.computed(function () {
		return this.get(key) != null && this.get(key) != undefined;
	}).property(key);
};

//Like not, but two way
Ember.computed.inverseBoolean = function (attribute, index) {
	return function (key, value) {
		if (arguments.length > 1) {
			this.set(attribute, !value);
		}
		return !this.get(attribute);
	}.property(attribute);
};

var formatDate = function (d) {
	if (!d) { return null; }
	return (1 + d.getMonth()) + "/" + d.getDate() + "/" + d.getFullYear();
};

var identity = function (o) { return o; };

zen._Employee401KMixin = Ember.Mixin.create({
	calculateEmployeeContributionPerMonth: function (contribution, salary, fixedContribution) {
		if ((!contribution || !salary) && (!fixedContribution)) {
			return 0;
		}
		else {
			if (fixedContribution != null) {
				return parseFloat(fixedContribution);
			}
			else {
				return parseFloat(((contribution * salary) / 100).toFixed(2));
			}
		}
	},
	calculateCompanyContribPercentageWithSalary: function (salary, actualCompanyContribution) {
		if (!salary) {
			return 0;
		}
		var companyDollarContribution = parseFloat(actualCompanyContribution || 0);
		return parseFloat(parseFloat((100 * companyDollarContribution) / salary).toFixed(2));
	},
	calculateCompanyContribPercentageWithoutSalary: function (nonElectivePercentageContribution, firstTierPercentageContribution, firstTierThreshold,
		secondTierPercentageContribution, secondTier, contribution, rothPercentageContribution, companyMatchingContribution) {

		var companyContribPercentageWithoutSalary = 0;
		var totalEmployeeContributionPercentage = parseFloat(contribution || 0) + parseFloat(rothPercentageContribution || 0);
		if (totalEmployeeContributionPercentage) {

			if (companyMatchingContribution) {
				companyContribPercentageWithoutSalary = Math.min(parseFloat(companyMatchingContribution), parseFloat(totalEmployeeContributionPercentage));
			}

			else if (firstTierPercentageContribution && firstTierThreshold) {
				if (totalEmployeeContributionPercentage <= firstTierThreshold) {
					companyContribPercentageWithoutSalary = parseFloat((totalEmployeeContributionPercentage * firstTierPercentageContribution) / 100).toFixed(2);
				}
				else {
					var percValue = parseFloat((firstTierThreshold * firstTierPercentageContribution) / 100);
					if (secondTierPercentageContribution && secondTier) {
						var employeeLeftOverPercentage = totalEmployeeContributionPercentage - firstTierThreshold; // > 0
						if (employeeLeftOverPercentage <= secondTier) {
							percValue += parseFloat((employeeLeftOverPercentage * secondTierPercentageContribution) / 100);
						}
						else {
							percValue += parseFloat((secondTier * secondTierPercentageContribution) / 100);
						}
					}
					companyContribPercentageWithoutSalary = parseFloat(percValue).toFixed(2);
				}
			}
		}
		nonElectivePercentageContribution = parseFloat(nonElectivePercentageContribution || 0);
		if (nonElectivePercentageContribution) {
			companyContribPercentageWithoutSalary = Math.max(nonElectivePercentageContribution, companyContribPercentageWithoutSalary);
		}
		return companyContribPercentageWithoutSalary;
	},
	calculateActualCompanyContribution: function (companyContribution, isFixed, employeeContributionPerMonth, employeeRothContributionPerMonth,
		companyFixedMatchingMaxContribution, salary, companyContributionAnnualMaximum, employerActualContributionYTD,
		nonElectivePercentageContribution, percentageEmployeeContributionMatch, firstTierPercentageContribution,
		firstTierThreshold, secondTierPercentageContribution, secondTier) {
		var companyDollarContribution = 0;

		if (companyContribution) {
			companyDollarContribution = parseFloat(companyFixedMatchingMaxContribution);
		}

		if (!employeeContributionPerMonth) {
			employeeContributionPerMonth = 0;
		}
		else {
			employeeContributionPerMonth = parseFloat(employeeContributionPerMonth);
		}
		if (!employeeRothContributionPerMonth) {
			employeeRothContributionPerMonth = 0;
		}
		else {
			employeeRothContributionPerMonth = parseFloat(employeeRothContributionPerMonth);
		}

		var totalEmployeeContributionPerMonth = employeeContributionPerMonth + employeeRothContributionPerMonth;
		if (!salary) {
			if (isFixed) {
				return Math.min(companyDollarContribution, totalEmployeeContributionPerMonth);
			}
			return 0;
		}

		var totalEmployeeContributionPercentage = parseFloat(totalEmployeeContributionPerMonth * 100 / salary);

		var nonElectiveContribution = 0;
		if (nonElectivePercentageContribution != null && salary != null) {
			nonElectiveContribution = parseFloat((salary * nonElectivePercentageContribution / 100).toFixed(2));
		}

		if (nonElectivePercentageContribution) {
			companyDollarContribution = parseFloat((salary * nonElectivePercentageContribution / 100).toFixed(2)); // #This value can be overridden below by below values
		}
		if (firstTierPercentageContribution) {
			if (totalEmployeeContributionPercentage <= firstTierThreshold) {
				companyDollarContribution = (totalEmployeeContributionPercentage / 100) * (firstTierPercentageContribution / 100) * (salary);
			}
			else {
				companyDollarContribution = (firstTierThreshold / 100) * (firstTierPercentageContribution / 100) * (salary);
				if (secondTierPercentageContribution && secondTier) {
					var employeeLeftOverPercentage = totalEmployeeContributionPercentage - firstTierThreshold; // > 0
					if (employeeLeftOverPercentage <= secondTier) {
						companyDollarContribution += (employeeLeftOverPercentage / 100) * (secondTierPercentageContribution / 100) * (salary);
					}
					else {
						companyDollarContribution += (secondTier / 100) * (secondTierPercentageContribution / 100) * (salary);
					}
				}
			}
		}
		else if (percentageEmployeeContributionMatch) {
			companyDollarContribution = parseFloat((percentageEmployeeContributionMatch * employeeContributionPerMonth) / 100); // ROth is not allowed, so we are good
		}
		else if (companyContribution) {
			companyDollarContribution = companyFixedMatchingMaxContribution;
		}

		// See models.py in retirement on example of below -- its always holds true by how we have defined companyMaxDollarContribution and nonElectivePercentageContribution
		if (nonElectiveContribution >= totalEmployeeContributionPerMonth) {
			companyDollarContribution = parseFloat(parseFloat(nonElectiveContribution).toFixed(2));
		}
		else if (companyDollarContribution > totalEmployeeContributionPerMonth) {
			companyDollarContribution = parseFloat(parseFloat(totalEmployeeContributionPerMonth).toFixed(2));
		}
		else {
			companyDollarContribution = parseFloat(parseFloat(companyDollarContribution).toFixed(2));
		}

		if (companyContributionAnnualMaximum) {
			if (employerActualContributionYTD + companyDollarContribution >= companyContributionAnnualMaximum) {
				companyDollarContribution = companyContributionAnnualMaximum - employerActualContributionYTD;
				if (companyDollarContribution < 0) {
					companyDollarContribution = 0;
				}
			}
		}
		return parseFloat(parseFloat(companyDollarContribution).toFixed(2));
	},
});

window.SPREADSHEET_COLUMN_TO_ERROR = zen.SPREADSHEET_COLUMN_TO_ERROR = {
	'Last Name': "Last Name: You have entered an invalid Last Name.",
	'First Name': "First Name: You have entered an invalid First Name.",
	'Address': "Address: You have entered an invalid home address.",
	'City': "City: You have entered an invalid city.",
	'State': "State: You have entered an invalid state.  Please use the drop down menu to select.",
	'Zip': "Zip Code: You have entered an invalid zip code. Please enter only 4 or 5 digits.",
	'Phone': "Phone Number: Must be 9-digit with area code",
	'Date of Birth': "Date of Birth: You have entered an invalid date of birth. Please enter in mm/dd/yyyy format",
	'Gender': "Gender: You have entered an invalid gender.  Please use the drop down menu to select.",
	'SSN': "Social Security Number: You have entered an invalid SSN.  Please enter only 9 digits.",
	'Department': "Department: You have entered an invalid department.  Please use the drop down menu to select.",
	'Job Title': "Job Title: You have entered an invalid job title",
	'Work Location': "Work Location: You have entered an invalid work location.  Please use the drop down menu to select.",
	'Email': "Email Address: You have entered an invalid business email address.",
	'Hire Date': "Hire Date: You have entered an invalid hire date. Please enter in mm/dd/yyyy format",
	'Termination Date': "Termination Date: You have entered an invalid termination date. Please enter in mm/dd/yyyy format",
	'Employment Type': "Employment Type: You have entered an invalid employment type.  Please use the drop down menu to select.",
	'Compensation Type': "Compensation Type: You have entered an invalid compensation type. Please use the drop down menu to select.",
	'Salary/Hourly Rate': "Salary / Hourly: You have entered an invalid rate.",
	'Bank Account Number': "Bank Account Number: You have entered an invalid account number",
	'Bank Routing Number': "Bank Routing Number: You have entered an invalid routing number.  Please enter only 9 digits.",
	'Bank Account Type': "Bank Account Type: You have entered an invalid Bank Account Type.  Please use the drop down menu to select.",
	'Federal Filing Status': "Federal Filing Status: You have entered an invalid Federal Filing Status.  Please use the drop down menu to select.",
	'Federal Withholding Allowance': "Federal Withholding Allowance: You have entered an invalid federal withholding allowance.",
	'Additional Federal Withholding': "Additional Federal Withholding: You have entered an invalid additional federal withholding.",
	'State Filing Status': "State Filing Status: You have entered an invalid State Filing Status.  Please use the drop down menu to select.",
	'State Withholding Allowance': "State Withholding Allowance: You have entered an invalid state withholding allowance.",
	'Additional State Withholding': "Additional State Withholding: You have entered an invalid additional state withholding.",
	'FICA Exemption': "FICA Exemption: You have entered an invalid value.  Please use the drop down menu to select.",
	'inPayroll': "inPayroll: You have entered an invalid value.  Please use the drop down menu to select.",
	'T-Shirt Size': "T-Shirt Size: You have entered an invalid T-Shirt size.  Please use the drop down menu to select.",
	'Payment Method': "TODO(louis): payment method???",
};

// formats a list of strings into a human readable list, i.e. ['foo', 'bar', 'baz'] => 'foo, bar, and baz'
window.humanizeList = function (strings, conjunction) {
	if (!conjunction) {
		conjunction = 'and';
	}
	if (strings.length === 0) {
		return "";
	} else if (strings.length === 1) {
		return strings[0];
	} else if (strings.length === 2) {
		return strings.join(" " + conjunction + " ");
	} else {
		return strings.slice(0, -1).join(", ") + ", " + conjunction + " " + strings[strings.length - 1];
	}
};

window.prettyDate = zen.prettyDate = function prettyDate(date, outputFormat) {
	var defaultOutputFormat = "MMMM Do, YYYY";
	var momentDate;

	if (typeof date == "string") {
		momentDate = moment(date, [moment.ISO_8601, "M/D/YYYY", "YYYY-M-D"], true);
	} else if (date instanceof Date) {
		momentDate = moment(date);
	} else if (date && date._isAMomentObject) {
		momentDate = date;
	}

	if (!momentDate || (momentDate._isAMomentObject && !momentDate.isValid())) {
		return "N/A";
	}

	return momentDate.format(outputFormat || defaultOutputFormat);
};

window.prettyCurrency = zen.prettyCurrency = function prettyCurrency(number, preserveDecimals, precision, dropCurrencySymbol, currencySymbol) {
	precision = precision || 2;
	currencySymbol = currencySymbol || '$';
	var fixedPrecisionString = Math.abs(String(number).replace(/[,$]/g, '')).toFixed(precision),
		sign = number < 0 ? '-' : '',
		formattedFixedPrecisionString = fixedPrecisionString.replace(/\d(?=(\d{3})+\.)/g, '$&,');
	if (!preserveDecimals) {
		formattedFixedPrecisionString = formattedFixedPrecisionString
			.replace(/\.0+$/, '')
			.replace(/(\.\d{2}(?:\d*[^0])?)0+$/, '$1');
	}
	if (dropCurrencySymbol) {
		return ((number || number === 0) && (sign + formattedFixedPrecisionString)) || '';
	}

	return ((number || number === 0) && (sign + currencySymbol + formattedFixedPrecisionString)) || '';
};

zen.tableDate = function (date) {
	var defaultOutputFormat = "MMM DD, YYYY";
	return zen.prettyDate(date, defaultOutputFormat);
};

// Ember extensions for models
// Don't rely on Ember Data for these.
Ember.PromiseObject = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
Ember.PromiseArray = Ember.ArrayProxy.extend(Ember.PromiseProxyMixin);
Ember.SortablePromiseArray = Ember.PromiseArray.extend(Ember.SortableMixin);


(function () {
	// Returns a random integer in range [min, max], inclusive. Assumes max - min < 2^32.
	function randomInt(min, max) {
		var width = max - min + 1;
		if (window && window.crypto && typeof window.crypto.getRandomValues === 'function') {
			var value = -1;
			// Get a random int in [0, 2^32) and return one in [0, width), by rejection sampling.
			// For the probabilities to be exactly equal, we reject values greater than the largest
			// multiple of width. This will be very rare. (Max when width = 2^31 + 1, but still ok.)
			while (value === -1 || value >= Math.pow(2, 32) - Math.pow(2, 32) % width) {
				var buf = new window.Uint32Array(1);
				window.crypto.getRandomValues(buf);
				value = buf[0];
			}
			return min + value % width;
		} else {
			return Math.floor(Math.random() * (max - min + 1) + min);
		}
	}

	function randomString(len) {
		var choices = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var ret = "";
		for (var i = 0; i < len; i++) {
			ret += choices[randomInt(0, choices.length - 1)];
		}
		return ret;
	}

	zen._PublicFilePickerMixin = Ember.Mixin.create({
		pickAndStore: function (propName, options, callback) {
			var _this = this;
			options = Ember.$.extend(options || {}, {
				"container": 'window',
				"services": ['COMPUTER', 'GMAIL', 'DROPBOX', 'BOX', 'GOOGLE_DRIVE']
			});
			window.filepicker.setKey(window.FILEPICKER_KEY);
			var newUploadPromise = new Ember.RSVP.Promise(function (resolve, reject) {
				window.filepicker.pickAndStore(options, { "location": "S3" },
					function (FPFile) {
						if (propName) { _this.set(propName, FPFile[0].url); }
						if (callback) { callback(null, FPFile); }
						Ember.run(function () {
							resolve(FPFile[0].url);
						});
					},
					function (FPError) {
						// TODO
						//console.log(FPError.toString());
						if (callback) { callback(FPError); }
						Ember.run(reject);
					}
				);
			});
			_this.set('uploadingPromise', Ember.RSVP.all([
				_this.get('uploadingPromise'),
				newUploadPromise,
			]));
		}
	});


	zen._FilePickerMixin = Ember.Mixin.create({
		uploadingPromise: null,
		pickAndStore: function (propName, options, callback, uploader, employee) {
			var newUploadPromise = new Ember.RSVP.Promise(function (resolve, reject) {
				var filePickerOptions = Ember.$.extend(options || {}, {
					"container": 'window',
					"services": ['COMPUTER', 'GOOGLE_DRIVE', 'BOX', 'DROPBOX']
				});
				window.filepicker.setKey(window.FILEPICKER_KEY);
				window.filepicker.pickAndStore(
					filePickerOptions,
					{ "location": "S3" },
					function (FPFile) {
						// Probability of collision < 1 in 10^34, even if we have 10^12 file uploads!
						window.dashboard('employee').then(function (dashboard_employee) {
							var s3_key = FPFile[0].key;
							var random_key = randomString(32);
							var record = App.S3File.createRecord({
								'random_key': random_key,
								's3_key': s3_key,
								'uploader': uploader || dashboard_employee,
								'employee': employee || null,
							});
							record.save().then(function () {
								var url = window.location.protocol + '//' + window.location.host + '/files/' + random_key;
								if (propName) { this.set(propName, url); }
								if (callback) { callback(null, FPFile); }
								resolve(url);
							}.bind(this));
						}.bind(this));
					}.bind(this),
					function (FPError) {
						// TODO
						//console.log(FPError.toString());
						if (callback) { callback(FPError); }
					}
				);
			}.bind(this));
			this.set('uploadingPromise', Ember.RSVP.all([
				this.get('uploadingPromise'),
				newUploadPromise,
			]));
		}
	});

	zen._FilePickerMixinWithFileType = Ember.Mixin.create({
		pickAndStore: function (fileType, propName, options, callback, uploader, employee) {
			var filePickerOptions = Ember.$.extend(options || {}, {
				"container": 'window',
				"services": ['COMPUTER', 'GMAIL', 'DROPBOX'],
				"mimetypes": ["application/pdf", "image/*", "video/*"]
			});
			window.filepicker.setKey(window.FILEPICKER_KEY);
			window.filepicker.pickAndStore(
				filePickerOptions,
				{ "location": "S3" },
				function (FPFile) {
					window.dashboard('employee').then(function (dashboard_employee) {
						var s3_key = FPFile[0].key;
						var random_key = randomString(32);
						var record = App.S3File.createRecord({
							'random_key': random_key,
							's3_key': s3_key,
							'uploader': uploader || dashboard_employee,
							'employee': employee || null,
						});
						record.save();
						var url = window.location.protocol + '//' + window.location.host + '/files/' + random_key;
						var type = FPFile[0].mimetype;
						if (type.length > 5 && (type.substring(0, 5) == 'image' || type.substring(0, 5) == 'video' || type.substring(type.length - 3) == 'pdf')) {
							if (propName) { this.set(propName, url); }
							if (fileType) { this.set(fileType, type); }
							if (callback) { callback(null, FPFile); }
						}
					}.bind(this));
				}.bind(this),
				function (FPError) {
					if (callback) { callback(FPError); }
				}.bind(this)
			);
		}
	});
})();

window.dashboard = function (path) {
	var container = (window.ClientApp && window.ClientApp.__container__) ||
		(window.App && window.App.__container__) ||
		window.container;
	var d = container.lookup('store:main').find('dashboard', 'me');
	if (!path) {
		return d;
	}
	return zen.thenpath(d, path);
};

zen._BlobHelperMixin = Ember.Mixin.create({
	createBlob: function (previousId, content, companyId) {
		var contentMd5 = window.btoa(window.md5(content, null, true));
		var data = { companyId: companyId };
		return Ember.ajaxPost("/custom_api/documents/sign_s3_upload", JSON.stringify({
			contentMd5: contentMd5,
			previousId: previousId
		})).then(function (response) {
			if (response.state == 'created') {
				return { blobId: response.blobId, toSave: response.toSave };
			}
			data.contentUrl = response.uploadUrl;
			data.urlSignature = response.urlSignature;
			data.expires = response.expires;
			data.previousId = previousId;
			return Ember.ajax({
				method: 'PUT',
				url: response.uploadUrl,
				data: content,
				headers: { 'Content-MD5': contentMd5, 'Content-Type': 'application/octet-stream' },
			}).then(function (response) {
				return Ember.ajaxPost("/custom_api/documents/create_blob", JSON.stringify(data));
			});
		});
	},
	createBlobByType: function (type) {
		return Ember.ajaxPost("/custom_api/documents/create_blob", JSON.stringify({ type: type }));
	},
	getBlob: function (id) {
		if (id == null) {
			return Ember.RSVP.resolve(null);
		}
		return Ember.ajaxPost("/custom_api/documents/get_blob/" + id);
	},
});

// Get rid of getAdminPromise once medicalsettings.overview gets deprecated. This function isn't used in too many places. Can be localized.
zen.getAdminPromise = function getAdminPromise(result, lineOfCoverage) {
	var enrollments = result[0];
	var carriers = result[1];
	var promises = [];
	var activeIds = [];
	if (enrollments == null || carriers == null) { return; }
	enrollments.forEach(function (enrollment) {
		if (enrollment.get('isActive')) {
			activeIds.push(enrollment.get('companyHealthCarrier.id'));
		}
	});

	carriers.forEach(function (carrier) {
		if (activeIds.contains(carrier.get('id'))) {
			if (carrier.get('lineOfCoverage') == lineOfCoverage) {
				promises.pushObject(window.wrapArrayPromise(carrier.get('companyHealthPlans')));
			}
		}
	});
	return Ember.RSVP.all(promises);
};

App._AuthAdminMixin = zen._AuthAdminMixin = Ember.Mixin.create({
	beforeModel: function (transition) {
		return window.dashboard('isAdmin').then(function (isAdmin) {
			if (!isAdmin) { throw new Error('Not Authorized'); }
		});
	}
});

/**
 *  Mixin for delaying rapid-type searches until user finishes entering input.

	In the controller, you need to specify debouncedProperties, which is an array of arrays.

	Each inner array is [fieldName, options]

	Options can include a delay (default is 500) and a debounced field name
	(optional, will be generated if you don't specify: "field" ---> "debouncedField")

	Example:

	debouncedProperties: [
		['carrierFilter', { delay: 1000 }],
		['companyFilter', { debouncedPropertyName: "customDebouncedCompanyFilter", delay: 1000 }],
		['descriptionFilter', { delay: 1000 }]
	],

	Your actions/properties should probably .observes() or .property() on the debounced property field,
	but .get() from the original property field

	Example:

	carrierFilterProperty: function() {
		var carrierFilter = this.get('carrierFilter');
		if (carrierFilter) {
			return ['carrier', new RegExp(carrierFilter, 'i')];
		}
	}.property('debouncedCarrierFilter'),

 */

zen._DebouncedPropertiesMixin = Ember.Mixin.create({
	debouncedProperties: undefined,
	setupDebouncedProperties: function () {
		if (this.debouncedProperties !== undefined) {
			this.debouncedProperties.forEach(function (property) {
				var propertyName = property[0];
				var options = property[1] || {};
				options.debouncedPropertyName = options.debouncedPropertyName || "debounced" + Ember.String.capitalize(propertyName);
				options.delay = options.delay || 500;

				// If you really, really want to see the documentation on this function, search for
				// function defineProperty(obj, keyName, desc, data, meta) inside
				// ember-1.11.3.js
				Ember.defineProperty(this, options.debouncedPropertyName, undefined, null);

				var propertySetter = function () {
					if (!this.isDestroying || !this.isDestroyed) {
						this.set(options.debouncedPropertyName, this.get(propertyName));
					}
				};
				var propertyDidChange = function () {
					Ember.run.debounce(this, propertySetter, options.delay);
				};
				this.addObserver(propertyName, this, propertyDidChange);
			}, this);
		}
	}.on('init'),
});

Ember.SelectOption.reopen({
	attributeBindings: ['disabled'],

	init: function () {
		this.disabledPathDidChange();
		this._super();
	},

	disabledPathDidChange: Ember.observer('parentView.optionDisabledPath', function () {
		var valuePath = this.get('parentView.optionDisabledPath');

		if (!valuePath) {
			this.set('disabled', null);
			return;
		}

		Ember.defineProperty(this, 'disabled', Ember.computed(valuePath, function () {
			return this.get(valuePath);
		}));
	}),
});


// IMPORTANT: please leave this at the bottom of the file
zen.globalizeSpecials();
